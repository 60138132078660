
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { isDefined } from "../../config/helpers";
import useWindowSize from "../../_hooks/useWindowSize";

import dynamic from "next/dynamic";
import React, { useEffect } from "react";
import useGlobalSettings from "@components/_hooks/useGlobalSettings";

const FooterMobile = dynamic(() => import("./FooterMobile"), {
    ssr: true,
});

const FooterDesktop = dynamic(() => import("./FooterDesktop"), {
    ssr: true,
});


const FooterContactForm = dynamic(() => import("./FooterContactForm"), {
    ssr: true
});

const date = new Date().getFullYear();


const Footer = () => {
    
    const {isMobile, width} = useWindowSize();
    const {open} = useSelector((state) => state.snackbarReducer);
    const VATFlag = useSelector(state => state.globalReducer.VATFlag);
    const template_settings = useSelector(state => state.globalReducer.template_settings);
    const agent = useSelector(state => state.globalReducer.agent);
    const {getSetting} = useGlobalSettings();

    const mobilePage = (width < 1200) || (!isDefined(width) && isDefined(isMobile) && isMobile) ? true : false
    
    const payment_types = [
        "visa",
        "masterCard",
        "paypal",
        "american-express",
        "klarna",
    ];
    const layout = template_settings.layout ? template_settings.layout : "wide";
    const layout_width =
        layout == "custom" ? template_settings.custom_layout_width : "0px";

    return (
        <footer>

            <FooterContactForm isMobile={mobilePage} />
            {/* {open && <SnackBar />} */}
            {(mobilePage) ? ( //mobile
                <>
                    <FooterMobile/>
                </>
                
            ) : ( //desktop
                <FooterDesktop />
            )}


            <Box className="text-center p-4 mt-2 background-grey">
                <Typography>&copy; {date} {getSetting('office_name', 'Central Event Hire')} Ltd. All Rights Reserved. All prices are {VATFlag ? 'inc' : 'ex'} VAT.</Typography>
            </Box>
        </footer>
    );
};

export default React.memo(Footer)
